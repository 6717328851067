<template>
  <button
      role="progressbar" aria-valuemax="1" aria-valuemin="0"
      disabled="disabled" class="btn disabled btn-disabled css-175oi2r r-1777fci"
      style="align-items: center;width:100%">
    <SAHSpinner></SAHSpinner>
  </button>
</template>
<script>

import SAHSpinner from "./SAHSpinner.vue";
export default {
  name: 'DisabledProgressButton',
  components: {SAHSpinner},
  props: [],
  data() {
    return {

    }
  },
  created() {
  },
}
</script>
