/* eslint no-ex-assign: 0 */
/* eslint no-unused-vars: 0 */

import axios from 'axios';

// Create a shared configuration object
const sharedConfig = {
    baseURL: '',
    headers: {
        common: {} // Keep the common property for axios compatibility
    },
    use_axios: false,
    retryStatusCodes: [502],
    retryLimit: 5,
    retryDelay: 333,
    timeout: 0,
    params: {},
    auth: null,
    responseType: 'json',
    maxContentLength: -1,
    maxBodyLength: -1,
    validateStatus: function (status) {
        return status >= 200 && status < 300; // default
    },
    withCredentials: false
};

// Interceptors
const interceptors = {
    request: [],
    response: []
};

function deepMerge(target, source) {
    for (const key in source) {
        if (source[key] instanceof Object && key in target) {
            Object.assign(source[key], deepMerge(target[key], source[key]));
        }
    }
    Object.assign(target || {}, source);
    return target;
}

async function runInterceptors(interceptors, config) {
    for (const interceptor of interceptors) {
        if (interceptor.onFulfilled) {
            config = await interceptor.onFulfilled(config);
        }
    }
    return config;
}

async function smart_fetch(url, options = {}) {
    const mergedOptions = deepMerge({}, sharedConfig);
    deepMerge(mergedOptions, options);

    // Handle common headers for axios
    if (mergedOptions.use_axios) {
        mergedOptions.headers = {
            ...mergedOptions.headers.common,
            ...mergedOptions.headers
        };
    } else {
        // For fetch, remove the common property
        const { common, ...fetchHeaders } = mergedOptions.headers;
        mergedOptions.headers = fetchHeaders;
    }

    // Run request interceptors
    let config = await runInterceptors(interceptors.request, mergedOptions);

    const {
        use_axios,
        retryStatusCodes,
        retryLimit,
        retryDelay,
        body,
        ...fetchOptions
    } = config;

    let retries = 0;

    while (retries < retryLimit) {
        try {
            let response;
            if (use_axios) {
                const axiosOptions = {
                    ...fetchOptions,
                    url,
                    method: fetchOptions.method || 'GET',
                    data: body
                };
                response = await axios(axiosOptions);
            } else {
                response = await fetch(url, { ...fetchOptions, body });
            }

            const status = use_axios ? response.status : response.status;

            // Run response interceptors
            response = await runInterceptors(interceptors.response, response);

            if (!retryStatusCodes.includes(status)) {
                return response;
            }

            retries++;

            if (retries === retryLimit) {
                if (!response.ok) {
                    throw new Error(`Request failed after ${retryLimit} retries. Status: ${status}`);
                }
                return response;
            }

            await new Promise(resolve => setTimeout(resolve, retryDelay));
        } catch (error) {
            // Run response interceptors for errors
            error = await runInterceptors(interceptors.response, error);

            if (retries === retryLimit - 1) {
                throw error;
            }
            retries++;
        }
    }
}

smart_fetch.get = async function (url, options = {}) {
    return smart_fetch(url, { ...options, method: 'GET' });
};

// Expose defaults
smart_fetch.defaults = sharedConfig;

// Expose interceptors
smart_fetch.interceptors = {
    request: {
        use: (onFulfilled, onRejected) => {
            interceptors.request.push({ onFulfilled, onRejected });
        }
    },
    response: {
        use: (onFulfilled, onRejected) => {
            interceptors.response.push({ onFulfilled, onRejected });
        }
    }
};

export default smart_fetch;


