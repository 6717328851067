<template>
  <div class="sah-progress-bar  css-175oi2r r-z80fyv r-19wmn03 r-17bb2tj r-1muvv40 r-127358a r-1ldzwu0">
    <svg height="22.5px" viewBox="0 0 32 32" width="100%">
      <circle cx="16" cy="16" fill="none" r="14" stroke-width="4"
              style="stroke: var(--sah-blue); opacity: 0.2;"></circle>
      <circle cx="16" cy="16" fill="none" r="14" stroke-width="4"
              style="stroke: var(--sah-blue); stroke-dasharray: 80; stroke-dashoffset: 60;"></circle>
    </svg>
  </div>
</template>
<script>

export default {
  name: 'SAHSpinner',
  props: [],
  data() {
    return {

    }
  },
  created() {
  },
}
</script>