<template>
  <div class="flex items-center w-full justify-center h-screen">
    <div v-if="!this.requestValidated">
      <h1 class=" font-bold text-xl">Invalid signature request.</h1>
    </div>
    <div v-if="this.requestValidated && this.submitting === true">
      <SAHSpinner/>
    </div>
    <div v-if="this.requestValidated && this.signed === true && this.submitting == false">
      <h1 class="font-bold text-xl">Your signature has been saved. You may close the page.</h1>
    </div>
    <div v-if="this.requestValidated && this.signed !== true && this.submitting == false"
         class="flex items-center  justify-center h-screen" style="max-width:min(100%,936px);width: min(100%, 936px);">
      <div role="status" v-if="this.pdfContent === null" class="mt-[-60px]">
        <SAHSpinner/>
      </div>
      <div ref="iframe" v-else
           class="mb-4 overflow-y-scroll z-0 force-scroll"
           aria-label="View the filled PDF"
           style="width: 75vw; max-height:90vh;  border: 1px solid var(--sah-text-color); box-shadow: var(--sah-box-shadow);"
      >
        <VuePdfEmbed :key="this.pdfRefresh" scale="4" :source="`data:application/pdf;base64,${this.pdfContent}`"/>


      </div>


    </div>

  </div>

  <div v-if="this.pdfContent !== null && !this.signed && !this.submitting"
       class="flex items-center w-full justify-center flex-col">
    <a class="btn btn-link mt-[-45px]" :key="this.pdfRefresh + 'download'" v-if="this.pdfContent" target="_blank"
       @click="this.DownloadPDF()">Download PDF</a>


    <div style="max-width:min(100%,936px);width: min(100%, 936px);">
      <a name="start-sign"></a>
      <SignatureCanvas @signed="(base64Img) => {
                                                    this.SaveSignature(base64Img)
                                         }"
                       :submitting="this.submitting"
                       :binding-legal-text="this.bindingText"
                       :sessionId="this.sessionId"
                       :userMetadata="this.userMetadata"
      />
    </div>
  </div>
</template>

<script>
import SignatureCanvas from "@/components/SignatureCanvas";
import SAHSpinner from "@/components/SAHSpinner";

import VuePdfEmbed from 'vue-pdf-embed'

// essential styles
import 'vue-pdf-embed/dist/style/index.css'

// optional styles
import 'vue-pdf-embed/dist/style/annotationLayer.css'
import 'vue-pdf-embed/dist/style/textLayer.css'

import smart_fetch from './utils/smart_fetch.js';
import { SAH_GET_ALGOSURE_API } from "./utils/Cryptography";
import {toRaw} from "vue";

export default {
  name: 'RemoteSign',
  components: {SAHSpinner, SignatureCanvas, VuePdfEmbed},
  params: {
    signed: Boolean,
  },
  data() {
    return {
      pdfContent: null,
      signed: this.signed,
      submitting: false,
      pdfRefresh: 1,
      prevWidth: 1,
      prevHeight: 1,
      cache: new Map(),
      userMetadata: {},
    }
  },
  props: {
    msg: String,
    requestValidated: {
      type: Boolean,
      default: true,
    },
    secretKey: String,
  },
  created() {
    this.prevWidth = window.innerWidth;
    this.prevHeight = window.innerHeight;
  },
  mounted() {

    this.fetchUserMetadata();

    // get all query params
    const params = new URLSearchParams(window.location.search);

    // make sure we have a sessionId and a signId
    if (!params.has('sessionId') || !params.has('signId')) {
      this.$emit('requestValidated', false);
      return;
    }

    if (!this.secretKey) {
      this.$emit('requestValidated', false);
      return;
    }

    // add the secret key to the signId
    params.set('signId', `${this.signId}${this.secretKey}`);

    // fetch the content
    const url = `https://api.rapidsign.net/pdf/get-encrypted-pdf?${params.toString()}`;

    // url is json, get the pdf param and then set the iframe source to a data string from the pdf
    // do cors proxy to get around cors issues


    setTimeout(() => {
      fetch(url)
          .then(response => {
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            return response.json();
          })
          .then(data => {
            const pdf = data.pdf;
            const bindingText = data.bindingLegalText;
            this.pdfContent = pdf;
            this.bindingText = bindingText;
          })
          .catch(error => {
            this.$emit('error', 'Either your code has expired or is incorrect.');
            console.error('There has been a problem with your fetch operation:', error);
            this.$emit('requestValidated', false);
          });
    }, 333); // delay 333ms to mitigate automated attacks

    // on window resize, refresh the pdf
    window.addEventListener('resize', this.RefreshPDF);

  },
  computed: {
    sessionId() {
      const params = new URLSearchParams(window.location.search);
      return params.get('sessionId');
    },
    signId() {
      const params = new URLSearchParams(window.location.search);
      return params.get('signId');
    }
  },
  methods: {
    async GetISAAPIAuth(validationObject) {

      if (this.cache && this.cache.has('ISAAPIAuth')) {
        return this.cache.get('ISAAPIAuth');
      }

      const Host = 'https://reap.insurancesoftwareautomation.com/demo/rapidsign';


      const [algosure_key, algosure_timestamp] = await SAH_GET_ALGOSURE_API(
          {
            'validation_object': validationObject,
            'is_public_api_obfuscated': false,
            'public_api_key': 'eapp_api_transmission',
            'host': Host,
            'sessionId': this.sessionId,
            'session_id': this.sessionId,
          }
      );

      const auth = JSON.stringify({
        'Authorization': algosure_key,
        '*Timestamp': algosure_timestamp,
        '*Host': Host,
        '*ValidationObject': validationObject,
        '*sessionId': this.sessionId,
      });

      this.cache.set('ISAAPIAuth', auth);

      return auth;
    },
    async fetchUserMetadata() {

      if (this.userMetadata) {
        // is city set?
        if (this.userMetadata.__geolocation?.city) {
          return this.userMetadata;
        }
      }

      const userMetadata = {
        __localtime: new Date().toLocaleString(),
        __timestamp: new Date().toLocaleString(),
        __date: `${new Date().toLocaleString('en-US', { month: 'short' })} ${(new Date().getDate()).toString().padStart(2, '0')}, ${new Date().getFullYear()}`,
        __sign_day: new Date().getDate(),
        __sign_month_year: `${(new Date().getMonth() + 1).toString().padStart(2, '0')}/${new Date().getFullYear()}`,
        __browserInfo: {
          browser: navigator?.userAgent,
          platform: navigator?.platform
        }
      };

      // Fetch IP Address from an external API
      let ipAddress = null;
      try {

        const ipResponse = await smart_fetch('https://isaapi.com/proxy/get-user-info',
            {
              method: 'GET',
              headers: {
                'Authorization': await this.GetISAAPIAuth('ipaddress'),
              },
            });
        const ipData = await ipResponse.json();
        ipAddress = ipData.ip;
      } catch (e) {
        console.error(e);
      }
      userMetadata.__ipAddress = ipAddress;



      // Fetch Geolocation data based on IP Address
      let geoData = ipAddress?.city ? ipAddress : null;

      if (!geoData) {
        try {
          const geoResponse = await fetch(`https://ipapi.co/${ipAddress}/json/`);
          geoData = await geoResponse.json();
        } catch (e) {
          console.error('Error fetching geolocation data:', e);
        }
      }

      if (geoData) {
        const { city, region, country_name } = geoData;
        const formatLocation = (...parts) => parts.filter(Boolean).join(', ').trim();

        userMetadata.__geolocation = {
          city,
          region,
          country: country_name,
          city_state: formatLocation(city, region),
          city_state_country: formatLocation(city, region, country_name)
        };
      }



      this.userMetadata = userMetadata;
      Object.freeze(this.userMetadata);

      return userMetadata;
    },
    RefreshPDF() {
      const currentHeight = window.height;
      const currentWidth = window.innerWidth;

      if (currentWidth == this.prevWidth) {
        return;
      }

      this.pdfRefresh++;

      this.prevHeight = currentHeight;
      this.prevWidth = currentWidth;
    },
    base64ToUint8Array(base64) {
      const binaryString = window.atob(base64); // Decode base64 string to binary string
      const len = binaryString.length;
      const bytes = new Uint8Array(len); // Create a Uint8Array
      for (let i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i); // Convert binary string to char code
      }
      return bytes;
    },
    DownloadPDF() {
      if (this.pdfContent === null) {
        return;
      }

      const blob = new Blob([this.base64ToUint8Array(this.pdfContent)], {type: 'application/pdf'});
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.target = '_blank';
      a.download = `document-${new Date().toISOString().split('T')[0]}.pdf`; // or whatever you want the filename to be
      a.click();
      window.URL.revokeObjectURL(url);

    },
    SaveSignature(base64Img) {
      const url = `https://api.rapidsign.net/pdf/save-remote-signature`;
      this.submitting = true;

      // Prepare the data to be sent in the request
      let data = {
        signature: base64Img,
        secret: this.secretKey,
        userMetadata: JSON.stringify(toRaw(this.userMetadata)),
      };

      // merge the query params with the data
      const params = new URLSearchParams(window.location.search);
      params.forEach((value, key) => {
        data[key] = value;
      });

      // Set the necessary headers for the request
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'authorized', // Ensure this is the correct way to handle authorization for your API
      };

      // Send a POST request to the server
      fetch(url, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(data)
      })
          .then(response => {
            // Check if the response is successful
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            return response.text();
          })
          .then(data => {
            // Handle the data received from the server
            if (data == "saved") {
              this.signed = true;
              this.pdfContent = null;
              this.$emit('signed', true);
              this.submitting = false;
            } else {
              this.$emit('requestValidated', false);
            }
          })
          .catch(error => {
            // Handle any errors that occurred during the fetch operation
            console.error('There has been a problem with your fetch operation:', error);
            this.$emit('requestValidated', false);
          });
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}
</style>
