/* eslint no-unused-vars: 0 */
/* eslint no-unreachable: 0 */

import {_fusc, get_simple_derived_key} from './secure-validation-library';
import  smart_fetch  from './smart_fetch';

export async function SAH_Encrypt_With_API_Tech(strToEncrypt, args = {}) {
    const [salt, encrypt_time] = await SAH_Get_Secure_Salt(
        {
            'validation_object': { 'key': strToEncrypt },
            'is_public_api_obfuscated': false,
            'public_api_key': args?.public_api_key ? args?.public_api_key : 'resend',
            ...args,
        }
    );

    const encrypted_auth = await encryptWithAES(salt, strToEncrypt)

    return [encrypted_auth, encrypt_time];
}

export async function SAH_GET_ALGOSURE_API(args) {
    const [simple_derived_key, timestamp] = await SAH_Get_Secure_Salt({ ...args });
    let derived_key = simple_derived_key;

    // // Encrypt the derived key using the validation object, session ID, and public API key
    // derived_key = await _aes256_encrypt(derived_key, derived_key, validation_object);
    // derived_key = await _aes256_encrypt(derived_key, session_id, authorizer);
    // derived_key = await _aes256_encrypt(derived_key, public_api_key, authorizer);


    let validationObject = args.validation_object;
    if (typeof validationObject == 'object') validationObject = JSON.stringify(validationObject);

    derived_key = await encryptWithAES(derived_key, validationObject);
    derived_key = await encryptWithAES(derived_key, args.session_id);
    derived_key = await encryptWithAES(derived_key, args.public_api_key);

    return [derived_key, timestamp];
}

export async function SAH_Get_Secure_Salt(args) {
    // this implements a patent-pending invention

    const _SECONDS = 1000;
    const APIConfig = {
        "authorizer": args?.host ? args.host : 'https://reap.insurancesoftwareautomation.com/demo/rapidsign/',
        "public_api_key": "test_key",
        "authorizer_headers": {},
        "authorizer_request_type": "GET",
        "sleep_delay": 0.3 * _SECONDS,
    }

    // Create derived key
    const [salt, time] = await get_simple_derived_key({
        validation_object: args.validation_object,
        session_id: args.sessionId,
        public_api_key: args.is_public_api_obfuscated ? _fusc(args.public_api_key) : args.public_api_key,
        config: APIConfig,
    });

    return [salt, time];

}

export async function SAH_Fetch(url) {
    const transmit = {
        'Target': url,
        'RequestType': 'GET',
    };

    // Define the request options
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(transmit),
    };

    // Execute the fetch request
    return smart_fetch('https://isaapi.com/proxy', requestOptions);
}

import crypto from 'crypto';

// Client-Side Encryption Function
async function encryptWithAESClient(salt, value) {
    const crypto = window.crypto;

    const encoder = new TextEncoder();
    const passwordBuffer = encoder.encode(salt);
    const ivString = salt + 'iv';
    const ivBuffer = encoder.encode(ivString);

    const hashAlgo = 'SHA-256';
    // Hash both password and IV in parallel to reduce time
    const [keyHash, ivHash] = await Promise.all([
        crypto.subtle.digest('SHA-256', passwordBuffer),
        crypto.subtle.digest('SHA-256', ivBuffer)
    ]);
    const key = keyHash.slice(0, 32);
    const iv = ivHash.slice(0, 16);

    const plainTextBuffer = encoder.encode(value);

    const aesKey = await crypto.subtle.importKey(
        'raw',
        key,
        { name: 'AES-CBC' },
        false,
        ['encrypt']
    );

    const encryptedBuffer = await crypto.subtle.encrypt(
        { name: 'AES-CBC', iv: iv },
        aesKey,
        plainTextBuffer
    );


    const binaryString = new Uint8Array(encryptedBuffer).reduce((data, byte) => data + String.fromCharCode(byte), '');

    let base64String = await binaryToBase64(binaryString);
    return base64String;
}

// Client-Side Decryption Function
async function decryptWithAESClient(salt, encryptedValue) {
    const crypto = window.crypto;

    const decoder = new TextDecoder();
    const encoder = new TextEncoder();
    const passwordBuffer = encoder.encode(salt);
    const ivString = salt + 'iv';
    const ivBuffer = encoder.encode(ivString);

    const hashAlgo = 'SHA-256';

    // Hash both password and IV in parallel to reduce time
    const [keyHash, ivHash] = await Promise.all([
        crypto.subtle.digest('SHA-256', passwordBuffer),
        crypto.subtle.digest('SHA-256', ivBuffer)
    ]);

    const key = keyHash.slice(0, 32);
    const iv = ivHash.slice(0, 16);

    const encryptedBuffer = Uint8Array.from(atob(encryptedValue), c => c.charCodeAt(0));

    const aesKey = await crypto.subtle.importKey(
        'raw',
        key,
        { name: 'AES-CBC' },
        false,
        ['decrypt']
    );

    const decryptedBuffer = await crypto.subtle.decrypt(
        { name: 'AES-CBC', iv: iv },
        aesKey,
        encryptedBuffer
    );

    const plainText = decoder.decode(decryptedBuffer);

    return plainText;
}

// Server-Side Encryption Function
async function encryptWithAESServer(salt, value) {
    const hashAlgo = 'sha256';

    const keyHash = crypto.createHash(hashAlgo).update(salt).digest();
    const key = keyHash.slice(0, 32);

    const ivHash = crypto.createHash(hashAlgo).update(salt + 'iv').digest();
    const iv = ivHash.slice(0, 16);

    const cipher = crypto.createCipheriv('aes-256-cbc', key, iv);

    let encrypted = cipher.update(value, 'utf-8', 'base64');
    encrypted += cipher.final('base64');

    return encrypted;
}

// Server-Side Decryption Function
async function decryptWithAESServer(salt, encryptedValue) {
    const hashAlgo = 'sha256';

    const keyHash = crypto.createHash(hashAlgo).update(salt).digest();
    const key = keyHash.slice(0, 32);

    const ivHash = crypto.createHash(hashAlgo).update(salt + 'iv').digest();
    const iv = ivHash.slice(0, 16);

    const decipher = crypto.createDecipheriv('aes-256-cbc', key, iv);

    let decrypted = decipher.update(encryptedValue, 'base64', 'utf-8');
    decrypted += decipher.final('utf-8');

    return decrypted;
}

// Wrapper Functions
export async function encryptWithAES(salt, value) {
    if (typeof window !== 'undefined' && window.crypto) {
        // Client-side
        return await encryptWithAESClient(salt, value);
    } else {
        // Server-side
        return await encryptWithAESServer(salt, value);
    }
}

export async function decryptWithAES(salt, encryptedValue) {
    if (typeof window !== 'undefined' && window.crypto) {
        // Client-side
        return await decryptWithAESClient(salt, encryptedValue);
    } else {
        // Server-side
        return await decryptWithAESServer(salt, encryptedValue);
    }
}


export async function binaryToBase64(binaryString) {
    return btoa(binaryString);
    // Convert binary string to base64 string in chunks using btoa of 1MB
    const MEGABYTE = 1048576;
    const promises = [];

    for (let i = 0; i < binaryString.length; i += MEGABYTE) {
        const chunk = binaryString.slice(i, i + MEGABYTE);
        promises.push(new Promise((resolve) => {
            resolve(btoa(chunk));
        }));
    }

    const base64Chunks = await Promise.all(promises);
    return base64Chunks.join('');
}

export async function base64ToBinary(base64String) {
    return atob(base64String)
    const MEGABYTE = 1048576;
    const promises = [];

    for (let i = 0; i < base64String.length; i += MEGABYTE) {
        const chunk = base64String.slice(i, i + MEGABYTE);
        promises.push(new Promise((resolve) => {
            resolve(atob(chunk));
        }));
    }

    const binaryChunks = await Promise.all(promises);
    return binaryChunks.join('');
}